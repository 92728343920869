import React, {createContext, useContext, Component} from 'react';
import {getAuth} from '../services/firebase';

export interface User {
  uid: string;
  displayName: string;
  photoURL: string;
  email: string;
}

const defaultUser: User = {
  uid: '',
  displayName: '',
  photoURL: '',
  email: '',
};
export const UserContext = createContext<User>(defaultUser);
interface Props {}
interface State {
  user: User;
}
class UserProvider extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {user: defaultUser};
  }
  componentDidMount = async () => {
    console.log('did mount');
    const auth = getAuth();
    if (!auth) {
      return;
    }
    console.log('set up onAuthStateChanged listener');
    auth.onAuthStateChanged(async (userAuth: firebase.User | null) => {
      if (!userAuth) {
        this.setState({user: defaultUser});
        return;
      }
      const {uid, displayName, photoURL, email} = userAuth;
      const user = {
        uid,
        displayName: displayName ?? '',
        photoURL: photoURL ?? '',
        email: email ?? '',
      };
      this.setState({user});
    });
  };

  render() {
    const {user} = this.state;
    return (
      <UserContext.Provider value={user}>
        {this.props.children}
      </UserContext.Provider>
    );
  }
}
export const useUser = () => useContext(UserContext);
export const useIsLoggedIn = () => {
  const {uid} = useUser() || {uid: ''};
  return !!uid;
};
export default UserProvider;
