import firebase from 'firebase/app';
import 'firebase/auth';
// import "firebase/firestore";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyB1k5FbWffG00OoDyvX6tj5zZYCWY2dWss',
  authDomain: 'melogged-d9962.firebaseapp.com',
  databaseURL: 'https://melogged-d9962.firebaseio.com',
  projectId: 'melogged-d9962',
  storageBucket: 'melogged-d9962.appspot.com',
  messagingSenderId: '577976159315',
  appId: '1:577976159315:web:54934350f026115ef98aa6',
  measurementId: 'G-99R363VYJG',
};

// export const auth = firebase.auth();
// export const firestore = firebase.firestore();

export const getAuth = () => {
  if (window) {
    if (!firebase.apps.length) {
      console.log(firebase.apps);
      firebase.initializeApp(firebaseConfig);
    }
    return firebase.auth();
  }
};
export const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: 'popup',
  // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
//   signInSuccessUrl: '/',
  // We will display Google and Facebook as auth providers.
  signInOptions: [
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    firebase.auth.EmailAuthProvider.PROVIDER_ID,
    //   firebase.auth.FacebookAuthProvider.PROVIDER_ID
  ],
  callbacks: {
      // callback for successful login - returning false - to do nothing
    signInSuccessWithAuthResult: () => {return false;}
  }
};

const provider = new firebase.auth.GoogleAuthProvider();
export const signInWithGoogle = () => {
  const auth = getAuth();
  if (!auth) {
    return;
  }
  auth.signInWithPopup(provider);
};
export const signOut = async () => {
    const auth = getAuth();
    if (!auth) {
      return;
    }
  await auth.signOut();
};
export const generateUserDocument = async (
  user: firebase.User,
  additionalData: any,
) => {
  if (!user) return;

  const userRef = firestore.doc(`users/${user.uid}`);
  const snapshot = await userRef.get();

  if (!snapshot.exists) {
    const {email, displayName, photoURL} = user;
    try {
      await userRef.set({
        displayName,
        email,
        photoURL,
        ...additionalData,
      });
    } catch (error) {
      console.error('Error creating user document', error);
    }
  }
  return getUserDocument(user.uid);
};

const getUserDocument = async (uid: string) => {
  if (!uid) return null;
  try {
    const userDocument = await firestore.doc(`users/${uid}`).get();

    return {
      uid,
      ...userDocument.data(),
    };
  } catch (error) {
    console.error('Error fetching user', error);
  }
};
